import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './quizQs.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { EmbedPDF } from "@simplepdf/react-embed-pdf";


const QuizQs = () => {
  const location = useLocation();
  const { examination, level, subject, category } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [solutionUrl, setSolutionUrl] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const [questionNum, setQuestionNum] = useState(1);
  const [imageLoadingStates, setImageLoadingStates] = useState([]);
  const [isLoadingSolution, setIsLoadingSolution] = useState(true);
  // const [isMobileView, setIsMobileView] = useState(false);
  const isMobileView = (window.innerWidth < 1000)
  console.log(window.innerWidth)


  const fetchQuestions = async (page) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_QUIZ_BE_URL}/api/questions/questions-page`, {
        params: { page, limit: 10 ,examination, level, subject, category} 
      });
      setQuestions(response.data.questions);
      setTotalPages(response.data.totalPages);
      setImageLoadingStates(new Array(response.data.questions.length).fill(true)); 
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const [retryCount, setRetryCount] = useState(0);

  const loadSolutionWithRetry = (viewerUrl) => {
    setIsLoadingSolution(true);
    setSolutionUrl(`${viewerUrl}&retry=${retryCount}`);
    setTimeout(() => {
      setRetryCount((prev) => prev + 1);
    }, 2000);
  };

  useEffect(() => {
    fetchQuestions(currentPage);
    // setIsMobileView(window.innerWidth < 768);
  }, [currentPage, examination, level, subject, category]);

  const handleSolution = (question, num) => {
    let pdfUrl = question.ansLink;
    const viewerUrl = `https://docs.google.com/a/umd.edu/viewer?url=${pdfUrl}&embedded=true`;
    isMobileView ? setSolutionUrl(pdfUrl) : setSolutionUrl(pdfUrl); 
    setQuestionNum(num);
    setShowSolution(true);
  };


  return (
    <div className="quizqs_container">
      <h1 className="quizqs_title">{`${examination} - ${level} - ${subject} - ${category}`}</h1>
      {loading && <p className="quizqs_loading">Loading questions...</p>}
      {error && <p className="quizqs_error">{error}</p>}
  {/* Wrapper for questions and solution */}
  <div className="quizqs_questions-solution-container">
    {/* Questions Container */}
    <div className="quizqs_questions-container">
      {questions?.map((question, index) => (
        <div key={question._id} className="quizqs_question-item">
          <p>Question {index + 1 + (parseInt(currentPage) - 1) * 10} : </p>
          <div>
            <TransformWrapper
              initialScale={1}
              minScale={1}
              maxScale={3}
              wheel={{ step: 0.025 }} // Smooth wheel zooming on desktops
              pinch={{ step: 0.025 }} // Smooth pinch zooming on touch screens
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  {imageLoadingStates[index] && ( // Check loading state for this image
                    <img src="/Book.gif" alt="Loading..." className="loading-gif" />
                  )}
                  <TransformComponent>
                    <img
                      src={question.imgLink}
                      alt={question.category}
                      className="quizqs_question-image"
                      loading="lazy"
                      onLoad={() => {
                        setImageLoadingStates((prev) => {
                          const newState = [...prev];
                          newState[index] = false; // Set loading to false when image loads
                          return newState;
                        });
                      }}
                      onError={() => {
                        setImageLoadingStates((prev) => {
                          const newState = [...prev];
                          newState[index] = false; // Also set loading to false on error
                          return newState;
                        });
                      }}
                      style={{ maxWidth: '80%', height: 'auto', borderRadius: '4px' }}
                    />
                  </TransformComponent>
                  {!isMobileView && <div className="quizqs_zoom-controls">
                    <button onClick={() => zoomOut()} className="quizqs_zoom-button">-</button>
                    <button onClick={() => resetTransform()} className="quizqs_zoom-button">Reset</button>
                    <button onClick={() => zoomIn()} className="quizqs_zoom-button">+</button>
                  </div>}
                </>
              )}
            </TransformWrapper>
          </div>
          <p>Correct Answer: {question.correctAns}</p>
          {question.ansLink && (
            <button className="quizqs_button" onClick={() => handleSolution(question, index + 1 + (parseInt(currentPage) - 1) * 10)}>Show Solution</button>
          )}
            {isMobileView && showSolution && questionNum === index + 1 + (parseInt(currentPage) - 1) * 10 && (
                <div className="solution-iframe">
                  <div className="quizqs_qsn_close">
                    <p>Solution for Q - {questionNum} :</p>
                    <button className="quizqs_button" onClick={() => setShowSolution(false)}>Close Solution</button>
                  </div >
                  {/* {isLoadingSolution && <img src="/Book.gif" alt="Loading..." className="loading-gif" />} */}
                  {/* <iframe
                    src={`${solutionUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    title="Solution"
                    onLoad={(e) => {
                      // if (!e.target.contentDocument.body) {
                      // if (!e){
                        // loadSolutionWithRetry(solutionUrl);
                        // console.log(e);
                      // } else {
                        setIsLoadingSolution(false);
                      // }
                    }}
                    width="100%"
                    height="100%"
                    style={{ display: isLoadingSolution ? 'none' : 'block' }}
                  /> */}
                  <div style={{ width: '100%' }}>
                    <EmbedPDF companyIdentifier="viewer"
                    mode="inline"
                    style={{ width: '100%', height: '100vh' }}
                    documentURL={solutionUrl}
                  />
                  </div>
                </div>
              )}
        </div>
      ))}
    </div>

    {/* Solution Iframe */}
    {!isMobileView && showSolution && (
        <div className="solution-iframe">
          <div className='quizqs_qsn_close'>
            <p>solution for Q - {questionNum} : </p>
            <button className="quizqs_button" onClick={() => setShowSolution(false)}>Close Solution</button>
          </div>
          {isLoadingSolution && (
            <img src="/Book.gif" alt="Loading..." className="loading-gif" />
          )}
          <iframe 
            src={`${solutionUrl}#toolbar=0&navpanes=0&scrollbar=0`} 
            title="Solution"
            onLoad={() => setIsLoadingSolution(false)} // Set loading to false when iframe loads
            style={{ display: isLoadingSolution ? 'none' : 'block' }} // Hide iframe while loading
          />
        </div>
      )}
  </div>

  <div className="quizqs_pagination">
    {currentPage > 1 && (
      <button className="quizqs_button" onClick={() => setCurrentPage(currentPage - 1)}>
        &#9664; 
      </button>
    )}
    <span className="quizqs_page-info">Page {currentPage} of {totalPages}</span>
    {currentPage < totalPages && (
      <button className="quizqs_button" onClick={() => setCurrentPage(currentPage + 1)}>
        &#9654;
      </button>
    )}
  </div>
</div>  
  );
};


export default QuizQs;
