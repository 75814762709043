import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for the date picker
import './dashboard.css'; // Import your Tailwind CSS
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { format, eachDayOfInterval, isSameDay } from 'date-fns';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Dashboard({ activeUsers, usersActive }) {
  const [registeredUsers, setRegisteredUsers] = useState(0);
  const [sectionActivity, setSectionActivity] = useState({
    examQuestions: 0,
    quiz: 0,
    aLevel: 0,
  }); // Store section activity for each section
  const [startDate, setStartDate] = useState(new Date()); // Start date for range selection
  const [endDate, setEndDate] = useState(null); // End date for range selection
  const [chartData, setChartData] = useState(null); // Chart data

  useEffect(() => {
    const fetchUserStatistics = async () => {
      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd'); // Format start date
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : formattedStartDate; // Format end date or use startDate if endDate is null

        // Fetch data for the range
        const [registeredResponse, sectionActivityResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BE_URL}/api/statistics/registered-users`),
          axios.get(`${process.env.REACT_APP_BE_URL}/api/statistics/section-activity-range?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
        ]);

        // Determine whether we're dealing with a single date or a range
        const isSingleDate = !endDate || isSameDay(startDate, endDate);

        // If single date, show data for that specific day; if range, sum data
        let summedData = sectionActivityResponse.data.reduce(
          (acc, day) => {
            acc.quiz += day.quiz || 0;
            acc.aLevel += day.aLevel || 0;
            acc.examQuestions += day.examQuestions || 0;
            return acc;
          },
          { quiz: 0, aLevel: 0, examQuestions: 0 }
        );

        // If only a single date is selected, show the data for that exact date
        if (isSingleDate) {
          const singleDayData = sectionActivityResponse.data.find((day) => isSameDay(new Date(day.date), startDate));
          summedData = {
            quiz: singleDayData?.quiz || 0,
            aLevel: singleDayData?.aLevel || 0,
            examQuestions: singleDayData?.examQuestions || 0,
          };
        }

        setRegisteredUsers(registeredResponse.data.totalRegisteredUsers);
        setSectionActivity(summedData);

        // Prepare the data for the graph
        const allDatesInRange = eachDayOfInterval({
          start: new Date(formattedStartDate),
          end: new Date(formattedEndDate),
        }).map((date) => format(date, 'yyyy-MM-dd'));

        const sectionDataByDate = {};
        sectionActivityResponse.data.forEach((day) => {
          sectionDataByDate[day.date] = {
            quiz: day.quiz || 0,
            aLevel: day.aLevel || 0,
            examQuestions: day.examQuestions || 0,
          };
        });

        const quizData = allDatesInRange.map((date) => sectionDataByDate[date]?.quiz || 0);
        const aLevelData = allDatesInRange.map((date) => sectionDataByDate[date]?.aLevel || 0);
        const examQuestionsData = allDatesInRange.map((date) => sectionDataByDate[date]?.examQuestions || 0);

        setChartData({
          labels: allDatesInRange, // X-axis with all dates
          datasets: [
            {
              label: 'Quiz Users',
              data: quizData,
              borderColor: 'rgba(255, 99, 132, 1)', // Red color
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              fill: true,
            },
            {
              label: 'A-Level Users',
              data: aLevelData,
              borderColor: 'rgba(75, 192, 192, 1)', // Green color
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              fill: true,
            },
            {
              label: 'Exam Questions Users',
              data: examQuestionsData,
              borderColor: 'rgba(54, 162, 235, 1)', // Blue color
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              fill: true,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching user statistics:', error);
      }
    };

    fetchUserStatistics();
  }, [startDate, endDate]); // Fetch data when selected date range changes

  return (
    <div className="flex-grow p-8 ml-6">
      <div className="mb-4">
        <label className="font-semibold mr-2">Select Date Range:</label>
        <DatePicker
          selected={startDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          className="p-2 border rounded"
          dateFormat="dd/MM/yyyy"
          isClearable
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Card for Total Registered Users */}
        <div className="bg-orange-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Total Registered Users</h3>
          <p className="text-4xl mt-4">{registeredUsers || 'No registered users'}</p>
        </div>

        {/* Card for Active Users */}
        <div className="bg-yellow-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Active Users</h3>
          <p className="text-4xl mt-4">{activeUsers}</p>
        </div>

        {/* Card for Exam Questions Users */}
        <div className="bg-blue-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Exam Questions Users</h3>
          <p className="text-4xl mt-4">{sectionActivity.examQuestions || 0}</p>
        </div>

        {/* Card for Quiz Users */}
        <div className="bg-purple-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Quiz Users</h3>
          <p className="text-4xl mt-4">{sectionActivity.quiz || 0}</p>
        </div>

        {/* Card for A-Level Users */}
        <div className="bg-pink-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">A-Level Users</h3>
          <p className="text-4xl mt-4">{sectionActivity.aLevel || 0}</p>
        </div>
      </div>

      {/* Graph for Users Count */}
      <div className="mt-8">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">User Activity Over Time</h3>
        {chartData && (
          <Line
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Date',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'User Count',
                  },
                  beginAtZero: true,
                },
              },
            }}
            width={200}  // Adjust width
            height={70} // Adjust height
          />
        )}
      </div>

      {/* Active Users Table */}
      <div className="mt-8 p-6">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Active Users</h3>
        <table className="min-w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-200 text-gray-700 text-left">
              <th className="py-2 px-4 border-b">Username</th>
              <th className="py-2 px-4 border-b">Details</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(usersActive) && usersActive.length > 0 ? (
              usersActive.map((user, index) => {
                try {
                  const decodedUser = atob(user);
                  const [username, details] = decodedUser.split('-');
                  return (
                    <tr key={username} className="hover:bg-gray-100 transition duration-200">
                      <td className="py-2 px-4 border-b text-gray-700">{username}</td>
                      <td className="py-2 px-4 border-b text-gray-600">{details || 'No details available'}</td>
                    </tr>
                  );
                } catch (e) {
                  console.error('Error decoding user:', e);
                  return null; // Skip rendering this user if there's an error
                }
              })
            ) : (
              <tr>
                <td colSpan="2" className="text-center py-2">No active users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
