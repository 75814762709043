import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
import '../styles/TncPage.css';

const TncPage = () => {
    const dispatch = useDispatch();
    const { token, isAuthenticated } = useSelector((state) => state.auth);
    const [showTnCPopup, setShowTnCPopup] = useState(false);
    const [tncContent, setTncContent] = useState('');
    const [isTnCAccepted, setIsTnCAccepted] = useState(false);

    useEffect(() => {
        if (isAuthenticated && token) {
            const decodedToken = jwtDecode(token);
            const tncDate = decodedToken.tncDate;

            if (!tncDate) {
                setShowTnCPopup(true);
                loadTncContent();
            }
        }
    }, [isAuthenticated, token]);

    const loadTncContent = async () => {
        try {
            const response = await fetch('/TermandconditionsSST.html');
            const content = await response.text();
            setTncContent(content);
        } catch (error) {
            console.error('Error loading T&C content:', error);
        }
    };

    const handleAcceptTnC = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BE_URL}/tnc/accept-tnc`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            localStorage.setItem('token', response.data.token);
            console.log(response.data);
            localStorage.setItem('tncDate', response.data.tncDate);
            dispatch({ type: 'LOGIN_SUCCESS', payload: { token: response.data.token, tncDate: response.data.tncDate } });
            setShowTnCPopup(false);
        } catch (error) {
            console.error('Error accepting T&C:', error);
        }
    };

    return (
        <>
            {showTnCPopup && (
                <div className="tnc-overlay">
                    <div className="tnc-popup">
                        <h3 className="tnc-title">Terms and Conditions</h3>
                        <p className="tnc-message">Please accept our terms and conditions to proceed.</p>
                        <div
                            className="tnc-content-box"
                            dangerouslySetInnerHTML={{ __html: tncContent }}
                        ></div>
                        {/* Checkbox to accept TnC */}
                        <div className="tnc-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isTnCAccepted}
                                    onChange={(e) => setIsTnCAccepted(e.target.checked)}
                                />
                                I have read and accept the Terms and Conditions
                            </label>
                        </div>
                        <div className="tnc-button" onClick={handleAcceptTnC} disabled={!isTnCAccepted}>
                            Submit
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TncPage;
