import React from 'react';
import './quiz_sidebar.css'; // Import the custom CSS

const QuizSetSolution = ({ ansLink, onClose }) => {
  return (
    <div className="quiz-set-solution">
      <button
        onClick={onClose}
        className="close-button"
      >
        &times;
      </button>
      <div className="pdf-container">
        <iframe
          src={`${ansLink}#toolbar=0&navpanes=0&scrollbar=0`} 
          style={{ width: "100%", height: "100%" }}
          title="Solution PDF"
          frameBorder="0"
        />
      </div>
    </div>
  );
};

export default QuizSetSolution;
