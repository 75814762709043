import React from "react";
import { useNavigate } from "react-router-dom";
import "./access_exam_paper_main.css"; // Import your CSS file for styling

const AccessExamPaperMain = () => {
  const navigate = useNavigate(); // Get the navigate function

  return (
    <div className="access-exam-container">
      <h1>Select Subject</h1>
      <div className="button-container">
        <button
          onClick={() => navigate("/access_exam_paper_folder/physics_qp")}
          className="subject-button"
        >
          Physics
        </button>
        <button
          onClick={() => navigate("/access_exam_paper_folder/chemistry_qp")}
          className="subject-button"
        >
          Chemistry
        </button>
        {/* Add similar buttons for other subjects */}
      </div>
    </div>
  );
};

export default AccessExamPaperMain;
