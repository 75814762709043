import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
// import 'react-accessible-accordion/dist/fancy-example.css';
import "./math.css"


const SubjectDropdown = () => {
    const options = [
      {
        label: "KS3",
        suboptions: []
      },
      {
        label: "GCSEs",
        suboptions: ["AQA", "Edexcel", "Edexcel (IGCSE)", "OCR A"]
      },
      {
        label: "A-Levels",
        suboptions: ["AQA", "Edexcel", "OCR A"]
      }
    ];

    return (
        <div className='main_conatiner'>
          <div className='centered-container'>
          <Accordion className='accordian_container' allowMultipleExpanded="true">
            {options.map((option, index) => (
              <AccordionItem key={index} className='accordian_item'>
                  <AccordionItemHeading className='accordian_item_heading'>
                      <AccordionItemButton>
                          {option.label}
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  {/* Conditionally render AccordionItemPanel if suboptions exist */}
                  {option.suboptions.length > 0 && (
                    <AccordionItemPanel>
                        <ul>
                            {option.suboptions.map((suboption, subIndex) => (
                                <li key={subIndex}>{suboption}</li>
                            ))}
                        </ul>
                    </AccordionItemPanel>
                  )}
              </AccordionItem>
            ))}
        </Accordion>
        </div>
        </div>
    );
}

export default SubjectDropdown;
