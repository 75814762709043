import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';  
import QuizDurationCard from './QuizDurationCard';

function StudentDetail() {
  const location = useLocation();
  const { user } = location.state || {}; 
  const navigate = useNavigate();  
  const [moduleDurations, setModuleDurations] = useState([]);

  // Function to handle closing the details view and navigating back
  const handleClose = () => {
    navigate('/admin-dashboard/students');
  };

  // Fetch session data and calculate active durations for each module
  useEffect(() => {
    if (user) {
      axios.get(`${process.env.REACT_APP_BE_URL}/task/quiz-progress/${user._id}`)
        .then(response => {
          const sessions = response.data;
          if (!sessions || sessions.length === 0) {
            console.error('No session data found for the user');
            return; // Handle the case when no sessions are returned
          }

          // console.log("Fetched sessions: ", sessions)

          const durationsByModule = sessions.map(session => {
            const durationInSeconds = (new Date(session.endTime) - new Date(session.startTime)) / 1000;
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);
            const seconds = Math.floor(durationInSeconds % 60);
            return {
              moduleId: session.moduleId,
              duration: `${hours}h ${minutes}m ${seconds}s`
            };
          });

          setModuleDurations(durationsByModule);
        })
        .catch(error => {
          console.error('Error fetching session data:', error);
        });
    }
  }, [user]);

  if (!user) {
    return (
      <div className="p-8">
        <p>No student selected</p>
        <button onClick={handleClose} className="text-gray-500 p-2 text-lg">
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold mb-6 text-black-800">{user.name}</h1>
        <button onClick={handleClose} className="text-gray-500 p-2 text-lg">
          ✖
        </button>
      </div>
      <p><strong>Date of Birth:</strong> {new Date(user.dateOfBirth).toLocaleDateString()}</p>
      <p><strong>Email:</strong> {user.googleAccount}</p>
      <p><strong>Registration Time:</strong> {new Date(user.dateRegistered).toLocaleString()}</p>
      <p><strong>Last Active:</strong> {user.lastActive ? new Date(user.lastActive).toLocaleString() : 'Not active yet'}</p>

      <p><strong>Progress:</strong></p>
      {user.progress && Object.keys(user.progress).length > 0 ? (
        <ul>
          {Object.keys(user.progress).map((key) => (
            <li key={key}><strong>{key}:</strong> {user.progress[key].join(', ')}</li>
          ))}
        </ul>
      ) : (
        <span>No progress made</span>
      )}

      {/* Single card to display all module durations */}
      <div className="bg-white shadow-lg rounded-lg p-6 m-4">
        <h3 className="text-xl font-bold">Module Durations</h3>
        {moduleDurations.length > 0 ? (
          <ul>
            {moduleDurations.map((module, index) => (
              <li key={index}><strong>Module:</strong> {module.moduleId} - <strong>Duration:</strong> {module.duration}</li>
            ))}
          </ul>
        ) : (
          <p>No modules completed yet.</p>
        )}
      </div>
    </div>
  );
}

export default StudentDetail;
