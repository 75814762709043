import React, { useEffect, useState } from 'react';

const TncDisplay = () => {
  const [tncContent, setTncContent] = useState('');

  useEffect(() => {
    fetch('./TermandconditionsSST.html')
      .then(response => response.text())
      .then(data => setTncContent(data))
      .catch(error => console.error('Error loading HTML file:', error));
  }, []);

  // Define inline styles for specific elements
  const containerStyle = {
    maxWidth: '90%',
    margin: '40px auto',
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.8',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  };

  const styles = {
    h1: {
      color: '#0056b3',
      fontSize: '2em',
      fontWeight: 'bold',
      marginBottom: '15px',
      borderBottom: '2px solid #e0e0e0',
      paddingBottom: '5px',
    },
    h2: {
      color: '#0073e6',
      fontSize: '1.5em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    h3: {
      color: '#0099cc',
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '15px',
      marginBottom: '8px',
    },
    p: {
      fontSize: '1em',
      marginBottom: '15px',
      textAlign: 'justify',
    },
    span: {
      fontSize: '1em',
      color: '#555',
    },
  };

  return (
    <div style={containerStyle}>
      <div
        dangerouslySetInnerHTML={{ __html: tncContent }}
        style={{
          fontSize: '1em',
        }}
      />
      {/* Apply inline styles selectively */}
      <style>
        {`
          h1 { color: ${styles.h1.color}; font-size: ${styles.h1.fontSize}; font-weight: ${styles.h1.fontWeight}; border-bottom: ${styles.h1.borderBottom}; padding-bottom: ${styles.h1.paddingBottom}; margin-bottom: ${styles.h1.marginBottom}; }
          h2 { color: ${styles.h2.color}; font-size: ${styles.h2.fontSize}; font-weight: ${styles.h2.fontWeight}; margin-top: ${styles.h2.marginTop}; margin-bottom: ${styles.h2.marginBottom}; }
          h3 { color: ${styles.h3.color}; font-size: ${styles.h3.fontSize}; font-weight: ${styles.h3.fontWeight}; margin-top: ${styles.h3.marginTop}; margin-bottom: ${styles.h3.marginBottom}; }
          p { font-size: ${styles.p.fontSize}; margin-bottom: ${styles.p.marginBottom}; text-align: ${styles.p.textAlign}; }
          span { font-size: ${styles.span.fontSize}; color: ${styles.span.color}; }
        `}
      </style>
    </div>
  );
};

export default TncDisplay;
