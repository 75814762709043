import React, { useEffect, useState } from 'react';
import '../styles/PostHomework.css'; 
import axios from 'axios';
import { uploadFileToS3 } from './s3';
import { useSelector } from 'react-redux';

const GuideForm = () => {
    const [level, setLevel] = useState('');
    const [subject, setSubject] = useState('');
    const [topic, setTopic] = useState('');
    const [subtopic, setSubtopic] = useState('');
    const [content, setContent] = useState([]);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const userType = useSelector((state)=>state.auth.userType);


    const levelOptions = ['Primary', 'Lower Secondary', 'GCSE'];
    const subjectOptions = ['Biology', 'Physics', 'Chemistry'];
    const [topicOptions, setTopicOptions] = useState(['']);
    const [subtopicOptions, setSubtopicOptions] = useState(['']);

    
    const fetchUniqueTopics = async (params) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task/unique_topic`, { params });
            return response.data;
        } catch (error) {
            console.error("Error fetching unique topics:", error);
            throw error; 
        }
    };
    
    const fetchUniqueSubtopics = async (params) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task/unique_subtopic`, { params });
            return response.data;
        } catch (error) {
            console.error("Error fetching unique subtopics:", error);
            throw error; 
        }
    };

    useEffect(() => {
        const updatedParams = {};
        if (subject) updatedParams.subject = subject;
        if (topic) updatedParams.topic = topic;
        if (subtopic) updatedParams.subtopic = subtopic;
        setParams(updatedParams);
    }, [subject, topic, subtopic]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.subject) {
                    if (params.topic) {
                        const subtopics = await fetchUniqueSubtopics(params);
                        setSubtopicOptions(subtopics);
                    } else {
                        const topics = await fetchUniqueTopics(params);
                        setTopicOptions(topics);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [params]);

    const handleAddNewTopic = () => {
        const newTopic = prompt("Enter a new topic:");
        if (newTopic) {
            setTopicOptions([...topicOptions, newTopic]);
            setTopic(newTopic);
        }
    };

    const handleAddNewSubtopic = () => {
        const newSubtopic = prompt("Enter a new subtopic:");
        if (newSubtopic) {
            setSubtopicOptions([...subtopicOptions, newSubtopic]);
            setSubtopic(newSubtopic);
        }
    };

    const handleChange = (setter, index, value, state) => {
        const newState = [...state];
        newState[index] = value;
        setter(newState);
    };

    let uploadedUrl = '';

    const handleSubmit = async (event) => {
        setLoading(true); 
        uploadedUrl = '';
        event.preventDefault();

        if (!level || !subject || !topic || !subtopic) {
            alert('Please fill in all fields: level, subject, topic, and subtopic');
            return;
        }
    
        if (content.length > 0) {
            const path = `Tasks/${level}/${subject}/${topic}/${subtopic}`;
    
            try {
                for (let i = 0; i < content.length; i++) {
                    const file = content[i];
    
                    if (file.name.endsWith('.html')) {
                        uploadedUrl = await uploadFileToS3(file, `${path}/notes.html`);
                        console.log('Uploaded HTML File URL:', uploadedUrl);
                    } else if (file.type.startsWith('image/')) {
                        const imageUrl = await uploadFileToS3(file, `${path}/images/${file.name}`);
                        console.log('Uploaded Image File URL:', imageUrl);
                    }
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }
    
        const newGuideData = {
            "level": level,
            "subject": subject,
            "topic": topic,
            "subtopic": subtopic,
            "contentLink": uploadedUrl,
        };
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BE_URL}/guide`, newGuideData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            alert("Successfully added.")
            console.log('Response Status:', response); 
        } 
        catch (error) {
            console.error('There was an error!', error);
            alert('Failed to create task. Please try again.');
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
      {(userType === 'admin' || userType === 'marker') ? (
        <form className="questionForm items-center justify-center" onSubmit={handleSubmit}>
             <label>
                Level:
                <select value={level} onChange={(e) => setLevel(e.target.value)} required>
                    <option value="">Select Level</option>
                    {levelOptions.map((level, index) => (
                        <option key={index} value={level}>{level}</option>
                    ))}
                </select>
            </label>

            <label>
                Subject:
                <select value={subject} onChange={(e) => setSubject(e.target.value)} required>
                    <option value="">Select Subject</option>
                    {subjectOptions.map((subject, index) => (
                        <option key={index} value={subject}>{subject}</option>
                    ))}
                </select>
            </label>

            <label>
                Topic:
                <select value={topic} onChange={(e) => e.target.value === 'Other' ? handleAddNewTopic() : setTopic(e.target.value)} required>
                    <option value="">Select Topic</option>
                    {topicOptions.map((topic, index) => (
                        <option key={index} value={topic}>{topic}</option>
                    ))}
                    <option value="Other">Other</option>
                </select>
            </label>

            <label>
                Subtopic:
                <select value={subtopic} onChange={(e) => e.target.value === 'Other' ? handleAddNewSubtopic() : setSubtopic(e.target.value)}>
                    <option value="">Select Subtopic</option>
                    {subtopicOptions.map((subtopic, index) => (
                        <option key={index} value={subtopic}>{subtopic}</option>
                    ))}
                    <option value="Other">Other</option>
                </select>
            </label>

            <label>HTML and Image Files:</label>
                <div className="file-input">
                    <input
                        type="file"
                        webkitdirectory="true"
                        onChange={(e) => setContent(e.target.files)}
                        placeholder={`Select the folder containing the HTML file and images`}
                    />
                </div>

                <div className="flex items-center justify-center h-screen">
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                        className={`${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'Submitting...' : 'Submit Guide'}
                    </button>
                </div>

        </form>
        ) : (
            <div
              className="unauthorized-message"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh', 
                fontSize: '1.5rem', 
                // color: '#ff4d4d', 
                padding: '20px', 
                textAlign: 'center', 
              }}
            >
              Page Unauthorized..
            </div>
          )}
        </>
    )
}

export default GuideForm;
