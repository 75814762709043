import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { uploadFileToS3 } from '../../s3';

const EditSstHomework = () => {
  const location = useLocation();
  const homework = location.state?.homework; // Get homework object from state

  const [course, setCourse] = useState(homework?.course || '');
  const [homeworkTitle, setHomeworkTitle] = useState(homework?.homeworkTitle || '');

  const formatDueDate = (dateString) => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date.getTime() - offset).toISOString().slice(0, 16); // Format YYYY-MM-DDTHH:MM
    return localISOTime;
  };
  const [dueDate, setDueDate] = useState(homework?.dueDate ? formatDueDate(homework.dueDate) : '');

  const [description, setDescription] = useState(homework?.description || '');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  const containerStyle = {
    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px',
    height: '100vh', flexDirection: 'column', backgroundImage: 'url(/bg7.jpg)',
    backgroundSize:'fill',backgroundRepeat: 'repeat',  backgroundAttachment: 'fixed', width: '100%',
  };
  
  const formStyle = {
    display: 'grid', gridTemplateColumns: '1fr 2fr', gap: '10px',  alignItems: 'center', padding: '40px',
    maxWidth: '600px',  backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  
  const labelStyle = {
    textAlign: 'right',  paddingRight: '10px', fontWeight: 'bold', color: '#004aad',
  };
  
  const inputStyle = {
    padding: '8px', width: '100%', border: '1px solid #ccc', borderRadius: '4px', fontSize: '1rem',
    borderRadius: '4px'
  };
  
  const textareaStyle = {
    padding: '8px', width: '100%', height: '100px', border: '1px solid #ccc',
    borderRadius: '4px', resize: 'vertical', fontSize: '1rem',
  };
  
  const buttonStyle = {
    gridColumn: '2 / 3',  margin: '10px 0', padding: '10px 20px',
    backgroundColor: '#ffcc00',  color: 'black',  border: 'none',
    borderRadius: '4px',   cursor: 'pointer', fontSize: '1rem', transition: 'background-color 0.3s ease',
  };

  const errorStyle = {
    color: 'red',
    marginTop: '10px',
    textAlign: 'center',
  };

  const successMessageStyle = {
    color: '#004aad',
    marginTop: '10px',
    textAlign: 'center',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    const csplit = course.replace(' ', '_');
    const hsplit = homeworkTitle.replace(' ', '_');
    let fileLink = homework?.solution;

    const localDate = new Date(dueDate);
    // const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    const utcDate = new Date(localDate.toISOString());

    try {
      const path = `sstClassroom/${csplit}/${hsplit}/solution`;
      if(fileToUpload) fileLink = await uploadFileToS3(fileToUpload, path);
      setLoading(true);
      const response = await axios.patch(`${process.env.REACT_APP_BE_URL}/classroom/teacher/edit/${homework.course}/${homework.homeworkTitle}`, {
        homeworkTitle: homeworkTitle.trim().replace(/\s+/g, '_'),
        dueDate: utcDate.toISOString(),
        description,
        solution: fileLink
      });

      setSuccessMessage('Homework successfully Updated!');
      setTimeout(() => setSuccessMessage(false), 4000);
      console.log('Homework updated successfully!', response);
    } catch (error) {
        setError('Error creating homework. Please try again.');
      console.error('Error updating homework:', error);
    }finally {
        setLoading(false);
        setPdfPreviewUrl(null);
      }
  };

  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
      <label htmlFor="course" style={labelStyle}>Course Name:</label>
        <input id="course"
          type="text"
          value={course.replace(/_/g,' ')}
          onChange={(e) => setCourse(e.target.value)}
          disabled
          style={inputStyle}
        />

        <label htmlFor="homeworkTitle" style={labelStyle}>Homework Title:</label>
        <input
          id="homeworkTitle"
          type="text"
          value={homeworkTitle.replace(/_/g,' ')}
          onChange={(e) => setHomeworkTitle(e.target.value)}
          required
          style={inputStyle}
        />

        <label style={labelStyle} htmlFor="dueDate">
        Due Date & Time:
        </label>
        <input
            id="dueDate"
          type="datetime-local"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          required
          style={inputStyle}
          placeholder= {dueDate}
        />

        <label htmlFor="description" style={labelStyle}>Description:</label>
        <textarea
            id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          style={textareaStyle}
        />

        <label htmlFor="pdfUpload" style={labelStyle}>Upload Solution:</label>
        <input
          id="pdfUpload"
          type="file"
          accept="application/pdf"
          onChange={(e) => {
            const file = e.target.files[0];
            setFileToUpload(file);
            setPdfPreviewUrl(URL.createObjectURL(file));  // Generate preview URL
          }}
          style={inputStyle}
        />


        <button type="submit" disabled={loading} style={buttonStyle}>
          {loading ? 'Updating...' : 'Update Homework'}
        </button>
      </form>
      {error && <p style={errorStyle}>{error}</p>}
      {successMessage && <p style={successMessageStyle}>{successMessage}</p>}
      {pdfPreviewUrl &&
      <iframe
        src={pdfPreviewUrl}
        width="80%"
        height="250px"
      ></iframe>}
    </div>
  );
};

export default EditSstHomework;