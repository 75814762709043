import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import '../styles/ViewFile.css';

// Ensure the correct worker source for PDF.js is set
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const ViewReportPdf = () => {
  const { encodedUrl } = useParams();
  const decodedUrl = atob(encodedUrl);
  const [numPages, setNumPages] = useState(0);
  const canvasRefs = useRef([]);
  const [scale] = useState(1);  // Fixed scale for rendering

  // Load the PDF when decodedUrl is available
  useEffect(() => {
    const loadPdf = async () => {
      if (decodedUrl) {
        const pdf = await pdfjsLib.getDocument(decodedUrl).promise;
        setNumPages(pdf.numPages);

        // Render the PDF pages
        const renderPage = async (pageNum) => {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale });
          const canvas = canvasRefs.current[pageNum - 1];
          const context = canvas.getContext('2d');

          const outputScale = window.devicePixelRatio || 1;
          canvas.height = viewport.height * outputScale;
          canvas.width = viewport.width * outputScale;
          canvas.style.height = `${viewport.height}px`;
          canvas.style.width = `${viewport.width}px`;

          context.setTransform(outputScale, 0, 0, outputScale, 0, 0);
          context.clearRect(0, 0, canvas.width, canvas.height);

          const renderTask = page.render({ canvasContext: context, viewport });
          await renderTask.promise;
        };

        // Render all pages
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          renderPage(pageNum);
        }
      }
    };

    loadPdf();
  }, [decodedUrl, scale]);

  return (
    <div className="view-report-pdf-container">
      <div className="pdf-container">
        {decodedUrl ? (
          [...Array(numPages)].map((_, index) => (
            <canvas
              key={index}
              ref={(el) => (canvasRefs.current[index] = el)}
              className="pdf-canvas"
            />
          ))
        ) : (
          <p>No PDF available.</p>
        )}
      </div>
    </div>
  );
};

export default ViewReportPdf;
