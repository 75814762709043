import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { uploadFileToS3 } from '../s3';
import HomeworkSelector from './HomeworkSelector';

const GenSstHomework = () => {

  const [course, setCourse] = useState('');
  const [homeworkTitle, setHomeworkTitle] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [description, setDescription] = useState('');
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [courseOptions, setCourseOptions] = useState([]);
  const [ansLink, setAnsLink] = useState('');
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [allHws, setAllHws] = useState({});
  const [showHomeworkList, setShowHomeworkList] = useState(false);



  const containerStyle = {
    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px',
    height: '100vh', flexDirection: 'column',  backgroundImage: 'url(/bg7.jpg)', 
    backgroundSize:'fill',backgroundRepeat: 'repeat',  backgroundAttachment: 'fixed', width: '100%',
  };
  
  const formStyle = {
    display: 'grid', gridTemplateColumns: '1fr 2fr', gap: '10px',  alignItems: 'center', padding: '40px',
    maxWidth: '500px', backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  
  const labelStyle = {
    textAlign: 'right',  paddingRight: '10px', fontWeight: 'bold', color: '#004aad',
  };
  
  const inputStyle = {
    padding: '8px', width: '100%', border: '1px solid #ccc', borderRadius: '4px', fontSize: '1rem',
    borderRadius: '4px'
  };
  
  const textareaStyle = {
    padding: '8px', width: '100%', height: '100px', border: '1px solid #ccc',
    borderRadius: '4px', resize: 'vertical', fontSize: '1rem',
  };
  
  const buttonStyle = {
    gridColumn: '2 / 3',  margin: '10px 0', padding: '10px 20px',
    backgroundColor: '#004AAD',  color: 'white',  border: 'none',
    borderRadius: '4px',   cursor: 'pointer', fontSize: '1rem', transition: 'background-color 0.3s ease',
  };

  const errorStyle = {
    color: 'red',
    marginTop: '10px',
    textAlign: 'center',
  };

  const successMessageStyle = {
    color: '#004aad',
    marginTop: '10px',
    textAlign: 'center',
  };

  
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/all-courses`);
        setCourseOptions(response.data);
        const response2 = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/all-courses-hws`);
        setAllHws(response2.data.allHomeworks);
        // console.log(typeof(response2.data.allHomeworks));
      } catch (error) {
        console.error('Error fetching course names:', error);
      }
    };
  
    fetchCourses();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    const csplit = course.replace(' ', '_');
    const hsplit = homeworkTitle.replace(' ', '_');
    let fileLink='/temp.pdf';

    const localDate = new Date(dueDate);
    // const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    const utcDate = new Date(localDate.toISOString());
    
    try {
      const path = `sstClassroom/${csplit}/${hsplit}/solution`;
      if(fileToUpload) fileLink = await uploadFileToS3(fileToUpload, path);
      if(ansLink!=='') fileLink = ansLink;
      console.log(fileLink);
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BE_URL}/classroom/teacher/homework`, {
        course : course.trim().replace(/\s+/g, '_'),
        homeworkTitle : homeworkTitle.trim().replace(/\s+/g, '_'),
        dueDate: utcDate.toISOString(),
        description,
        solution: fileLink
      });

      // const studentsEmails = ['boddepallisai@iitbhilai.ac.in'];
       // Step 2: Trigger Vercel API to send emails (fire-and-forget)
      axios.post(`${process.env.REACT_APP_BE_URL}/email/create-hw`, {
        // emails: studentsEmails,
        homeworkTitle: homeworkTitle,
        course: course,
        dueDate: utcDate.toISOString()
      }).catch(error => {
        console.error('Error sending emails:', error); // Log any error without affecting user experience
      });

      setSuccessMessage('Homework successfully updated!');
      setCourse('');
      setHomeworkTitle('');
      setDueDate('');
      setDescription('');
      setTimeout(() => setSuccessMessage(false), 4000);
    } catch (error) {
      setError('Error creating homework. Please try again.');
      console.error('Error creating homework:', error);
    } finally {
      setLoading(false);
      setPdfPreviewUrl(null);
    }
  };

  return (
    <div style={containerStyle}>
      <button style={buttonStyle} onClick={() => setShowHomeworkList(true)}>Show All Homeworks</button>
      <HomeworkSelector
        showHomeworkList={showHomeworkList}
        setShowHomeworkList={setShowHomeworkList}
        allHws={allHws}
        setCourse={setCourse}
        setHomeworkTitle={setHomeworkTitle}
        setDueDate={setDueDate}
        setDescription={setDescription}
        setAnsLink={setAnsLink}
        setPdfPreviewUrl={setPdfPreviewUrl}
      />

      <form onSubmit={handleSubmit} style={formStyle}>
        <label htmlFor="course" style={labelStyle}>Course Name:</label>
        <select id="course" value={course} onChange={(e) => setCourse(e.target.value)} required style={inputStyle}>
            <option value="">Select Course</option>
            {courseOptions.map((courseOption, index) => (
              <option key={index} value={courseOption}>{courseOption.replace(/_/g,' ')}</option>
            ))}
          </select>

        <label htmlFor="homeworkTitle" style={labelStyle}>Homework Title:</label>
        <input
          id="homeworkTitle"
          type="text"
          value={homeworkTitle}
          onChange={(e) => setHomeworkTitle(e.target.value)}
          required
          placeholder="Enter homework title"
          style={inputStyle}
        />

        <label style={labelStyle} htmlFor="dueDate">
        Due Date & Time:
        </label>
        <input
        id="dueDate"
        type="datetime-local"
        value={dueDate}
        onChange={(e) => setDueDate(e.target.value)}
        required
        style={inputStyle}
        />
        

        <label htmlFor="description" style={labelStyle}>Description:</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          placeholder="Enter homework description"
          style={textareaStyle}
        ></textarea>

        <label htmlFor="pdfUpload" style={labelStyle}>Upload Solution:</label>
        <input
          id="pdfUpload"
          type="file"
          accept="application/pdf"
          onChange={(e) => {
            const file = e.target.files[0];
            setFileToUpload(file);
            setPdfPreviewUrl(URL.createObjectURL(file));  // Generate preview URL
          }}
          style={inputStyle}
        />
 
        <button type="submit" disabled={loading} style={buttonStyle}>
          {loading ? 'Creating...' : 'Create Homework'}
        </button>

      </form>

      {error && <p style={errorStyle}>{error}</p>}
      {successMessage && <p style={successMessageStyle}>{successMessage}</p>}
      {pdfPreviewUrl &&
      <iframe
        src={pdfPreviewUrl}
        width="80%"
        height="250px"
      ></iframe>}
    </div>
  );
};

export default GenSstHomework;
