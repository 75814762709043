export const uploadImagesToS3 = async (images, path) => {
  try {
    const urls = await Promise.all(
      images.map(async (file, index) => {
        const fileName = `${path}/${index + 1}.png`;

        const response = await fetch(`${process.env.REACT_APP_BE_URL}/s3Url`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ fileName }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch signed URL');
        }

        const { url } = await response.json();

        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'image/png' || file.type || 'application/octet-stream',
            // 'x-amz-acl': 'public-read'
          },
          body: file,
        });

        if (!uploadResponse.ok) {
          throw new Error('Failed to upload image to S3');
        }

        return url.split('?')[0];
      })
    );

    console.log('Uploaded URLs:', urls);
    return urls;
  } catch (err) {
    console.error('Error uploading images:', err);
    throw new Error('Failed to upload images. Please try again.');
  }
};


export const uploadFileToS3 = async (file, path) => {
  try {
    const fileName = `${path}`;

    const response = await fetch(`${process.env.REACT_APP_BE_URL}/s3Url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch signed URL');
    }

    const { url } = await response.json();

    const uploadResponse = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type || 'application/octet-stream',
        'Cache-Control': 'no-cache',
        // 'x-amz-acl': 'public-read'
      },
      body: file,
    });

    if (!uploadResponse.ok) {
      throw new Error('Failed to upload file to S3');
    }

    const uploadedUrl = url.split('?')[0];

    // console.log('Uploaded URL:', uploadedUrl);
    return uploadedUrl;
  } catch (err) {
    console.error('Error uploading file:', err);
    throw new Error('Failed to upload file. Please try again.');
  }
};