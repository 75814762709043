import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StudentDetailsPDF from '../admin/GenerateReport';

const StudentsReport = () => {
  const course = localStorage.getItem('course');
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/studentsRegistered`, {
          params: { course }
        });
        setStudents(response.data.sort((a, b) => a.name.localeCompare(b.name)));  
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };
    fetchStudents();
  }, []);

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  return (
    <div style={{ display: 'flex' }}>
      <aside style={{ width: '200px', borderRight: '1px solid #ccc', padding: '10px' }}>
        <h3>Students</h3>
        <ul>
          {students.map((student) => (
            <li key={student.email} onClick={() => handleStudentClick(student)} style={{ cursor: 'pointer' }}>
              {student.name} 
            </li>
          ))}
        </ul>
      </aside>
      <main style={{ flex: 1, padding: '10px' }}>
        {selectedStudent && (
          <div>
            <h2>Report for {selectedStudent.name}</h2>
            <StudentDetailsPDF email={selectedStudent.email} />
          </div>
        )}
      </main>
    </div>
  );
};

export default StudentsReport;
