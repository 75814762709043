import React, { useEffect, useState } from 'react';
import {useSelector} from 'react-redux' ;
import axios from 'axios';
import './quizHome.css';
import { useNavigate } from 'react-router-dom';
import { IoMdLock } from "react-icons/io";



const QuizHome = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector((state)=> state.auth.token);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_QUIZ_BE_URL}/api/questions/filter-1`);
        const sortedData = Object.keys(response.data).sort().reduce((acc, level) => {
          acc[level] = {};
          Object.keys(response.data[level]).sort().forEach(exam => {
            acc[level][exam] = {};
            Object.keys(response.data[level][exam]).sort().forEach(subject => {
              acc[level][exam][subject] = {
                // Sort categories by 'category' name
                categories: response.data[level][exam][subject].categories.sort((a, b) =>
                  a.category.localeCompare(b.category)
                ),
                questionCount: response.data[level][exam][subject].questionCount,
              };
            });
          });
          return acc;
        }, {});

        setData(sortedData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleQs = (examBoard, level, subject, category, index) => {
    // console.log(examBoard, level , subject, category)
    if (!token && index>0) {
      alert('Please login to view all questions');
      return; 
    }
    navigate('/quiz-qs', { state: { examination: examBoard, level, subject, category } });
  };


  // Render the hierarchical data
  const renderData = (data) => {
    return Object.keys(data).map((level) => (
      <div key={level} className="qh-exam-board">
        <h2 className="qh-exam-board-title">{level}</h2>
        <div className="qh-level-container">
          {Object.keys(data[level]).map((examBoard) => (
            <div key={examBoard}>
              {/* <h3 className="qh-level-title">{level}</h3> */}
              {Object.keys(data[level][examBoard]).map((subject) => (
                <>
                <h4 className="qh-subject-title">
                  {subject}
                  {subject !== "Physics" ? `  (Total Questions: ${data[level][examBoard][subject].questionCount})` :
                    `  (Total Q&As: ${data[level][examBoard][subject].questionCount})`}
                </h4>
                <div key={subject} className="qh-subject">
                  <ul className="qh-categories">
                    {data[level][examBoard][subject].categories.map((category,index) => (
                      <li 
                        key={category.category}
                        className={token ? "qh-category-item" : index === 0 ? "qh-category-item" : "qh-category-item-lock"}
                        onClick={() => handleQs(examBoard, level, subject, category.category, index)}
                      >
                      <span className="qh-category-item-label">{(!token && index > 0) ? <IoMdLock style={{ color: "#FFD43B", opacity: 0.7 }} /> : " "}  {category.category}</span>
                      <span>
                        {category.questionCount}
                      </span>
                    </li>                    
                    ))}
                  </ul>
                </div>
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <div className="quiz-home-container">
      <h1 style={{ textAlign: 'center' }}>A-level Questions</h1>
      <button className="qh-button" onClick={() => navigate("/access_exam_paper_folder/quiz_set")}>
        Quiz
      </button>
      <div className="qh-quiz-container">
        {renderData(data)}
      </div>
      <div><button className='qh-button' onClick={()=>navigate("/access_exam_paper_folder/quiz_set")}>Quiz</button></div>
    </div>
  );
};

export default QuizHome;
