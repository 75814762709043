import React, { useState } from 'react';
import PdfViewer from '../../PdfViewer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Grade.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const MarkerGrade = () => {
  const navigate = useNavigate();
  const [grade, setGrade] = useState(sessionStorage.getItem('grade') || '-');
  const [feedback, setFeedback] = useState(sessionStorage.getItem('feedback') || '-');
  const [status, setStatus] = useState(sessionStorage.getItem('status') || '');
  const prevStatus = status;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSolutionOpen, setIsSolutionOpen] = useState(true);

  const name = sessionStorage.getItem('name');
  const comments = sessionStorage.getItem('comments');
  const lastModified = sessionStorage.getItem('lastModified');
  const email = sessionStorage.getItem('email');
  const pdfUrl = sessionStorage.getItem('link'); 
  const solution = sessionStorage.getItem('solution'); 
  const course= localStorage.getItem('course');
  const homeworkTitle= localStorage.getItem('homeworkTitle');

  const csplit= course.replace(' ','_');
  const hsplit= homeworkTitle.replace(' ','_');
  const path = `sstClassroom/${csplit}/${hsplit}/${name}`;

  const toggleSolution = () => {
    setIsSolutionOpen(!isSolutionOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
        const response = await axios.patch(`${process.env.REACT_APP_BE_URL}/classroom/teacher/homework/grade`, {
            course: course, homeworkTitle:homeworkTitle, email: email,
            feedback: feedback, grade: grade, status: ((prevStatus === status) ? 'graded' : status )
        });

        if (response.status === 200) {
            alert('Grading done successfully!');
            // navigate(`/marker/${homeworkTitle}`);
            axios.post(`${process.env.REACT_APP_BE_URL}/email/grade`,{
                course: course, homeworkTitle:homeworkTitle, email: email, feedback: feedback, grade: grade
            })
            navigate(-1);
        }
    } catch (err) {
        console.error('Error updating grade:', err);
        setError('Failed to update grade.');
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="marker-grade-wrapper">
        <h2 className="marker-grade-heading">Grade and Feedback for {name.replace(/_/g,' ')}</h2>
        <div className="marker-grade-info">
            <div className="marker-grade-info-left">
                <div className="marker-grade-info-group">
                    <label className="marker-grade-label">Status:</label>
                    <input type="text" value={status} disabled className="marker-grade-input" />
                </div>
                <div className="marker-grade-info-group">
                    <label className="marker-grade-label">{name.replace(/_/g, ' ')}'s latest submission</label>
                    <input type="text" value={new Date(lastModified).toLocaleString()} disabled className="marker-grade-input" />
                </div>
                {comments && <div className="marker-grade-info-group">
                    <label className="marker-grade-label">Comments:</label>
                    <textarea value={comments} disabled className="marker-grade-textarea"></textarea>
                </div>}
            </div>
        </div>
        { solution && <p className="marker-copy-show-button" onClick={toggleSolution}>
        {isSolutionOpen ? 'Close solution' : 'Show solution'}
        </p>}

        <div>
        {pdfUrl ? (
            <div className='marker-show-files'>
            <div className={`marker-pdfviewer ${isSolutionOpen ? 'half-width' : 'full-width'}`}>
                 <PdfViewer  pdfUrl={pdfUrl} path={path} />
            </div>
            
            {solution && (
                <div className='marker-iframe' style={{ display: isSolutionOpen ? 'block' : 'none' }}>
                <div className="solution-header">
                    <h3>Solution</h3>
                </div>
                <iframe 
                    src={`${solution}#toolbar=0`} 
                    className="sst-preview-pdf" 
                ></iframe>
                </div>
            )}
            </div>
        ) : (
            <p>No PDF available to display.</p>
        )}
        </div>

    
        <form className="marker-grade-form-container" onSubmit={handleSubmit}>
            <div className="marker-grade-form-group">
                <label className="marker-grade-label" htmlFor="grade">Grade:</label>
                <input
                    type="text"
                    id="grade"
                    className="marker-grade-input"
                    value={grade !== undefined ? grade : ''}
                    onChange={(e) => setGrade(e.target.value)}
                />
            </div>
            <div className="marker-grade-form-group">
                <label className="marker-grade-label" htmlFor="feedback">Feedback:</label>
                <textarea
                    id="feedback"
                    className="marker-grade-textarea"
                    value={feedback !== undefined ? feedback : ''}
                    onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
            </div>
            <div className="marker-grade-form-group">
                <label className="marker-grade-label" htmlFor="status">Status:</label>
                <select
                    id="status"
                    className="marker-grade-select"
                    value={status !== undefined ? status : ''}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <option value="pending">Pending</option>
                    <option value="submitted">Re-Evaluate</option>
                    <option value="graded">Graded</option>
                </select>
            </div>
            <button
                type="submit"
                className="marker-grade-submit-button"
                disabled={loading}
            >
                {loading ? 'Submitting...' : 'Submit'} 
            </button>
            {error && <p className="marker-grade-error-message">{error}</p>}
        </form>
    </div>
  );
};

export default MarkerGrade;
