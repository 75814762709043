import React from 'react';
import './AboutUs.css'; // Import CSS file for styling
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import uclLogo from '../images/ucl.png';
import kingsCollegeLogo from '../images/kingsCollege.png';
import citiLogo from '../images/citi.png';
import fidessaLogo from '../images/fidessa.jpeg';
import highgateLogo from '../images/highgate.png';
import TransportForLondonLogo from '../images/transportForLondon.png';
import jknsLogo from '../images/jkns.png';

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    autoplay: true,
    autoplaySpeed: 2000,
   // Adjust the speed of autoplay (in milliseconds)
  easing: 'ease',
  };

  return (
    <div className="about-us-container">
      <h1 className="about-us-heading">About Mr. Siva</h1>
      <p className="about-us-description">
        Mr Siva has helped numerous students get their desired grades in their Science GCSE’s and Physics A-levels over the last eleven years. He has worked at a prestigious independent school in the UK, where 96% of the cohort got grades 7-9s in their GCSEs. He is currently teaching Physics at one of the most highly selective grammar schools in the UK. Mr Siva’s teaching approach is research-driven, his lessons are structured, exam-oriented with plenty of assessment for learning opportunities to improve students’ understanding. Mock examinations are carried out regularly to assess student’s progress and regular feedback is provided to parents to ensure they are up to date with their child’s progress. Mr Siva is a Mechanical Engineering graduate from University College London (UCL) and has a teaching qualification from King’s College London (KCL). He has worked in the financial and engineering sectors.
      </p>
     
      <Slider {...settings} >
        <div className='slider-slide'>
          <img src={uclLogo} alt="UCL Logo" className="company-logo" />
        </div>
        <div className='slider-slide'>
          <img src={kingsCollegeLogo} alt="King's College Logo" className="company-logo" />
        </div>
        <div className='slider-slide'>
          <img src={citiLogo} alt="Citi Logo" className="company-logo" />
        </div>
        <div className='slider-slide'>
          <img src={fidessaLogo} alt="Fidessa Logo" className="company-logo" />
        </div>
        <div className='slider-slide'>
          <img src={jknsLogo} alt="jkns Logo" className="company-logo" />
        </div>
        <div className='slider-slide'>
          <img src={highgateLogo} alt="highGate Logo" className="company-logo" />
        </div>
        <div className='slider-slide'>
          <img src={TransportForLondonLogo} alt="highGate Logo" className="company-logo" />
        </div>
      </Slider>
    </div>
  );
};

export default AboutUs;