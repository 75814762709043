import React from 'react';
import { TbMessageReport } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';

const ReportBugButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/components/ReportBug'); // Navigate to Report Bug page
  };

  // Inline styles
  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    // backgroundColor: '#007BFF', // Background color for the button
    fontSize: '24px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s',
  };

  const tooltipStyle = {
    position: 'fixed',
    bottom: '70px',
    right: '20px',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    visibility: 'hidden', // Default hidden
    opacity: 0, // Default opacity
    transition: 'opacity 0.3s',
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        style={buttonStyle}
        onClick={handleClick}
        onMouseEnter={(e) => {
          const tooltip = e.currentTarget.nextElementSibling;
          tooltip.style.visibility = 'visible'; 
          tooltip.style.opacity = 1; 
        }}
        onMouseLeave={(e) => {
          const tooltip = e.currentTarget.nextElementSibling;
          tooltip.style.visibility = 'hidden'; 
          tooltip.style.opacity = 0;
        }}
      >
        <TbMessageReport />
      </button>
      <div style={tooltipStyle}>Report Bug</div>
    </div>
  );
};

export default ReportBugButton;