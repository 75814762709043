export const SET_COURSE = 'SET_COURSE'
export const SET_HOMEWORK = 'SET_HOMEWORK';
export const RESET_CLASS = 'RESET_CLASS';

export const setCourse = (course) => ({
    type: SET_COURSE,
    payload: course,
});

export const setHomework = (homework) => ({
    type: SET_HOMEWORK,
    payload: homework,
});

export const resetClass = () => ({
    type: RESET_CLASS,
});
