import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Homeworks.css'; 
import { FaTrash } from "react-icons/fa6";
import { ImBooks } from "react-icons/im";
import { PiStudentFill } from "react-icons/pi";
import { IoMdSettings } from "react-icons/io";

const MarkerHomeworks = () => {
  const [homeworks, setHomeworks] = useState([]);
  const [error, setError] = useState('');
  const [openSolution, setOpenSolution] = useState(null); 
  const course = localStorage.getItem('course');
  const userType = localStorage.getItem('userType');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomeworks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/${course}/homeworks`);
        const sortedHomeworks = response.data.sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
        setHomeworks(sortedHomeworks);
      } catch (err) {
        console.error('Error fetching homeworks:', err);
        setError('Failed to load homeworks.');
      }
    };

    if (course) {
      fetchHomeworks();
    } else {
      setError('Course not found in localStorage');
    }
  }, [course]);

  const handleShowHomework = (homeworkTitle) => {
    localStorage.setItem('homeworkTitle', homeworkTitle);
    navigate(`/marker/${homeworkTitle}`);  //stufents.js
  }
  
  const handleEditHomework = (homework) => {
    console.log(homework);
    navigate('/edit-homework', { state: { homework } });
  }

  const handleDeleteHomework = async (course, homeworkTitle) => {
    if (window.confirm(`Deleting the homework : "${homeworkTitle.replace(/_/g,' ')}" from "${course.replace(/_/g,' ')}"? This action cannot be undone.`)) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BE_URL}/classroom/teacher/delete-homework`, {
          data: { course: course, homeworkTitle :homeworkTitle}
        });

        if (response.status === 200) {
          alert('Homework successfully deleted.');
        }
      } catch (error) {
        if (error.response) {
          alert(`Error: ${error.response.data.message}`);
        } else if (error.request) {
          alert('No response from the server. Please try again later.');
        } else {
          alert('An error occurred. Please try again.');
        }
      }
    } else {
      alert('Homework deletion canceled.');
    }
  };


  const handleViewSolution = (homeworkTitle) => {
    setOpenSolution((prev) => (prev === homeworkTitle ? null : homeworkTitle));
  };  

  return (
    <div className="marker-homework-container">
      <h2 className="marker-course-heading">Homeworks in {course.replace(/_/g,' ')}</h2>
      {error && <p className="marker-error-message">{error}</p>}
      <div className="marker-homework-table-container">
      <table className="marker-homework-table">
        <thead>
          <tr>
            <th>Homework Title</th>
            <th>Due Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
            {homeworks.map((homework, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{homework.homeworkTitle.replace(/_/g, ' ')}</td>
                  <td>{new Date(homework.dueDate).toLocaleDateString()}</td>
                  <td className="marker-homework-col3">
                    <button 
                      className="marker-show-homework-btn" 
                      onClick={() => handleShowHomework(homework.homeworkTitle)}
                      title="Show Homework" >
                      {/* Show Homework */}
                      <PiStudentFill />
                    </button>
                    {userType === 'admin' && (
                      <>
                        <button 
                          className="marker-show-homework-btn" 
                          onClick={() => handleEditHomework(homework)} title="Edit Homework">
                          {/* Edit Homework */}
                          <IoMdSettings/>
                        </button>
                        <button 
                          className="marker-show-homework-btn" 
                          onClick={() => handleDeleteHomework(course, homework.homeworkTitle)} title="Delete Homework">
                          <FaTrash />
                        </button>
                      </>
                    )}
                    {homework.solution && homework.solution !== './temp.pdf' && (
                      <button 
                        className="marker-show-homework-btn" 
                        onClick={() => handleViewSolution(homework.homeworkTitle)}
                        title="View Solution">
                        <ImBooks />
                      </button>
                    )}
                  </td>
                </tr>
                {openSolution === homework.homeworkTitle && (
                  <tr>
                    <td colSpan={3}>
                      <iframe 
                        src={homework.solution} 
                        width="100%" 
                        height="300px" 
                        onError={() => alert('Failed to load solution.')} 
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
      </table>
      </div>
    </div>
  );
};

export default MarkerHomeworks;