import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';

const AdminClasses = () => {
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]); // State to store the students' data
  const [selectedCourse, setSelectedCourse] = useState(null); // State to track the selected course
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/courses`);
        setCourses(response.data.sort()); 
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleCourseClick = async (course) => {
    localStorage.setItem('course', course);  
    setSelectedCourse(course); 
    try {
      const response = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/studentsRegistered`, {
        params: { course }
      });
      setStudents(response.data.sort((a, b) => a.name.localeCompare(b.name)));  
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };
  
  const handleDelete = async (student) => {
    const confirmDelete = window.confirm(`Are you sure you want to remove "${student.name}" from "${selectedCourse}"?`);
    const confirmRemove = window.confirm(`Do you want to change the UserType of "${student.name}" to  "student?" and Remove him from other classes too?`);
    
    if (!confirmDelete) {
      return; 
    }
    
    try {
      await axios.delete(`${process.env.REACT_APP_BE_URL}/admin/student`, { data: { email: student.email, course: selectedCourse, remove: confirmRemove} });
      alert(`${student.name} has been removed successfully.`); 
    } catch (error) {
      console.error('Error deleting student', error);
      alert('There was an error removing the student.'); 
    }
  };
  

  return (
    <div className="p-8 bg-[#ffffff] min-h-screen"> {/* Light golden background */}
      <div className="flex space-x-8">
        <div className="w-1/2">
          <h2 className="font-bold mb-6 text-[#004aad] border-b-4 border-[#ffcc00] font-serif letter-spacing: 2px;"> {/* Enhanced heading */}
            Classes - {courses.length}
          </h2>
          <ul className="space-y-4">
            {courses.map((course, index) => (
              <li key={index}>
                <button
                  className="text-left w-full bg-[#fef1be] hover:bg-[#ffe374] p-4 rounded-md shadow-lg transition-all ease-in-out duration-300 border border-[#004aad]"  // Updated button style
                  onClick={() => handleCourseClick(course)}
                >
                  {course}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Sidebar to show students */}
        <div className="w-1/2">
          <h3 className="text-xl font-semibold mb-6 text-[#004aad]"> {/* Enhanced sub-heading */}
            {selectedCourse ? `Students Registered for ${selectedCourse} (${students.length})` : 'Select a course to view students'}
          </h3>
          {students.length > 0 ? (
            <ul className="space-y-4">
              {students.map((student, index) => (
                <li key={index} className="bg-[#fef1be] p-1 rounded-md shadow-lg border-l-4 border-[#004aad] flex justify-between items-center">
                  <div className='ml-2'>
                    <div className="font-medium text-[#004aad]">
                      {student.name} 
                    </div>
                    <div className="text-sm text-gray-500">
                      {student.email}
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <FaTrash 
                      className="text-red-500 cursor-pointer mr-2" 
                      onClick={() => handleDelete(student)} 
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">
              {selectedCourse ? 'No students registered for this course.' : 'No course selected.'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminClasses;
