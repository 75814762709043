const HomeworkSelector = ({ showHomeworkList, setShowHomeworkList, allHws, setHomeworkTitle, setDueDate, setDescription, setCourse, setAnsLink, setPdfPreviewUrl }) => {
    const overlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    };
    
    const popupStyle = {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      width: '80%',
      maxHeight: '80vh',
      overflowY: 'auto',
    };
  
    const tableStyle = {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '10px',
    };
  
    const cellStyle = {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'left',
    };
  
    return (
      showHomeworkList && (
        <div style={overlayStyle} onClick={() => setShowHomeworkList(false)}>
          <div style={popupStyle} onClick={(e) => e.stopPropagation()}>
            <h3>Select Homework</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={cellStyle}>Course</th>
                  <th style={cellStyle}>Homework Title</th>
                  <th style={cellStyle}>Due Date</th>
                  <th style={cellStyle}>Description</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(allHws).map(([id, hw]) => (
                  <tr
                    key={id}
                    onClick={() => {
                      setCourse(hw.course)
                      setHomeworkTitle(hw.homeworkTitle);
                      setDueDate(new Date(hw.dueDate).toISOString().slice(0, 16));
                      setDescription(hw.description);
                      setShowHomeworkList(false);
                      setAnsLink(hw.solution);
                      setPdfPreviewUrl(hw.solution);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td style={cellStyle}>{hw.course}</td>
                    <td style={cellStyle}>{hw.homeworkTitle}</td>
                    <td style={cellStyle}>{new Date(hw.dueDate).toLocaleDateString()}</td>
                    <td style={cellStyle}>{hw.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setShowHomeworkList(false)} style={{ marginTop: '20px' }}>
              Close
            </button>
          </div>
        </div>
      )
    );
  };
  
  export default HomeworkSelector;
  