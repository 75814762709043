import React, { useState } from 'react';
import './test.css';
import jsPDF from 'jspdf';

const Test = () => {
    const [images, setImages] = useState([]);

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const newImages = [...images];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = () => {
                newImages.push({
                    data: reader.result,
                    name: file.name,
                });
                setImages(newImages);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        const newImages = [...images];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = () => {
                newImages.push({
                    data: reader.result,
                    name: file.name,
                });
                setImages(newImages);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDelete = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };
    
    const generatePDF = async () => {
    const doc = new jsPDF();
    // const pageWidth = doc.internal.pageSize.getWidth();
    // const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = 204;
    const pageHeight = 285;

    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const img = new Image();
        img.src = image.data;

        // Wait for the image to load so we can get its dimensions
        await new Promise((resolve) => {
            img.onload = () => {
                const imgWidth = img.width;
                const imgHeight = img.height;

                // Calculate scaling to fit the image within the page while preserving the aspect ratio
                const aspectRatio = pageWidth / pageHeight;
                const hr = imgHeight / pageHeight;
                const wr = imgWidth / pageWidth;
                let finalImgWidth, finalImgHeight;
                console.log(imgWidth, imgHeight);
                console.log(pageWidth, pageHeight);
                
                if (imgHeight > imgWidth) { // Potrait orientation
                    finalImgWidth = imgWidth / hr;
                    finalImgHeight = pageHeight;
                    console.log('here');
                } else { // Landscape orientation
                  finalImgWidth = imgWidth ;
                  finalImgHeight = pageHeight/ wr ;
                }

                console.log(finalImgWidth, finalImgHeight);

                // Center the image on the page
                const xOffset = 1;
                const yOffset = (-125);

                if (i > 0) {
                    doc.addPage();
                }

                // Add the image with calculated dimensions
                doc.addImage(img.src, 'JPEG', xOffset, yOffset, finalImgHeight, finalImgWidth, '', 'NONE', 270);
                resolve();
            };
            img.onerror = () => {
                console.error('Image load error');
                resolve();
            };
        });
    }

    doc.save('converted_images.pdf');
};


    return (
        <div className="app">
            <div className="nav">
                <div className="logo">
                    Image to PDF Converter
                </div>
                <label htmlFor="image-upload" 
                       className="upload-btn">
                    Upload Images
                </label>
                <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                />
            </div>
            <div
                className="container"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                {images.length === 0 ? (
                    <div className="drop-box">
                        <span className="drop-text">
                            Drag and drop images here
                        </span>
                        <input
                            type="file"
                            id="image-upload-drop"
                            accept="image/*"
                            multiple
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                    </div>
                ) : (
                    <div className="image-preview">
                        {images.map((image, index) => (
                            <div key={index} className="image-item">
                                {/* <img src={image.data} alt={image.name} /> */}
                                {/* <button
                                    className="delete-btn"
                                    onClick={() => handleDelete(index)}>
                                    Delete
                                </button> */}
                                <button className="pdf-btn" 
                                        onClick={generatePDF}>
                                    Convert to PDF
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Test;
