import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles/StatusCount.css';  
import { useNavigate } from 'react-router-dom';


const StatusCount = () => {
  const [homeworkData, setHomeworkData] = useState([]);
  const [allStudents, setAllStudents]= useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomeworkData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/status-count`);
        setHomeworkData(response.data); 
        const allcount = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/course-student-count`);
        // console.log(allcount);
        setAllStudents(allcount.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching homework data');
        setLoading(false);
      }
    };
    fetchHomeworkData();
  }, []);

  if (loading) {
    return <div className="statusCount-loading">Loading...</div>;
  }

  if (error) {
    return <div className="statusCount-error">{error}</div>;
  }

  const handleNav = (course, homeworkTitle) =>{
    localStorage.setItem('course', course);
    localStorage.setItem('homeworkTitle', homeworkTitle);
    navigate(`/marker/${homeworkTitle}`);
  }

  return (
    <div className="statusCount-table-container">
      <h2>Homework Status Counts</h2>
      <table className="statusCount-table">
        <thead>
          <tr>
            <th>Classes</th>
            <th>Homework Title</th>
            <th>Not Graded</th>
            <th>Graded</th>
            <th>No-Submissions</th>
            <th>Total Students</th>
          </tr>
        </thead>
        <tbody>
          {homeworkData.map((homework) => {
            const totalNotGraded = homework.statusCounts.submitted + homework.statusCounts.lateSubmission;
            // const totalNoSubmission = homework.statusCounts.pending + homework.statusCounts.reSubmit;
            const totalNoSubmission = allStudents[homework.course] - (homework.statusCounts.graded + totalNotGraded);
            const graded = homework.statusCounts.graded;
            
            return (
              <tr 
                key={homework._id} 
                className={totalNotGraded > 0 ? 'highlight-pending' : ''}
                onClick={()=>{handleNav(homework.course,homework.homeworkTitle )}}
              >
                <td>{homework.course.replace(/_/g,' ')}</td>
                <td>{homework.homeworkTitle.replace(/_/g,' ')}</td>
                <td>{totalNotGraded ? totalNotGraded : '-'}</td>
                <td>{graded ? graded : '-'}</td>
                <td>{totalNoSubmission ? totalNoSubmission : '-'}</td>
                <td>{allStudents[homework.course]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StatusCount;
