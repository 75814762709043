import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Announcement.css';

const MockExamAnnouncement = () => {
    const navigate = useNavigate();

    const handleLearnMore = () => {
        navigate('/views/contactUs');
    }

    return (
        <div className="noticeboard">
            <div className="notice">
                {/* <h1 className="glossy-heading">Attention!!</h1> */}
                <ul className="announcement-list">
                    <li className="highlight">📢 Get Ahead of the Game: Science Classes & Mock Exams Start Soon! 📢</li>
                </ul>
                <div className="announcement-details">
                    <h3 className="announcement-heading">What's on Offer:</h3>
                    <ul>
                        <li><b>Year 11:</b> Crucial mock exams to boost your GCSE science preparation, starting the first week of September.</li>
                        <li><b>Years 6-11:</b> Engaging science classes across all disciplines (Biology, Chemistry, Physics) to solidify your knowledge and skills, beginning the first week of September.</li>
                        <li><b>A-Levels:</b> In-depth Physics classes to prepare you for university studies and to succeed in your Physics A-levels.</li>
                    </ul>
                    <h3 className="announcement-heading">Why Choose Us:</h3>
                    <ul>
                        <li>Experienced science tutors passionate about your success.</li>
                        <li>Proven track record of improving science grades.</li>
                        <li>Small class sizes for personalized attention.</li>
                    </ul>
                    <p style={{ textAlign: "center" }}>Don't Miss Out ⏰⏰</p>
                    <p style={{ fontSize: "15px",textAlign:"center" }}>Limited spots available! Secure your place today and give yourself the best chance to achieve your academic goals in science.</p>
                </div>
                <button className="enroll-button" onClick={handleLearnMore}>Enroll Now</button>
            </div>
        </div>
    );
}

export default MockExamAnnouncement;