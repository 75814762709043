import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from 'axios';
import './dashboard.css'; 
import useTrackLastActive from '../../hooks/useTrackLastActive'; 

function StudentList() {
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState();
  const [lastActiveData, setLastActiveData] = useState({});
  const [dupe, setDupe] = useState([]);
  const [students, setStudents]= ([]);
  const navigate = useNavigate(); 
  const [sortOrder, setSortOrder] = useState({ field: '', order: '' });

  useEffect(() => {
    // Fetch student details from the getUserDetails endpoint
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/api/getUserDetails`);
        setUsers(response.data); // This data now includes student details and progress
        setDupe(response.data);
        const coursesResponse = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/courses`)
        setCourses(["All Students", ...coursesResponse.data]);
        // console.log(coursesResponse.data);
  
        // Add console log to verify if lastActive is present in the response
        // console.log('Fetched users:', (response.data[0]));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUsers();

    const updateLastActive = () => {
      const token = localStorage.getItem('token'); // Assuming JWT is stored in localStorage
      // console.log(token);
      // console.log('called');
      axios.post(`${process.env.REACT_APP_BE_URL}/api/statistics/track-last-active`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      }).catch((error) => {
        console.error('Error updating last active time:', error);
      });
    };

    // Establish WebSocket connection to fetch last active timestamps
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);
    // updateLastActive();
    
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'lastActiveUpdate') {
        setLastActiveData(prev => ({
          ...prev,
          [message.userId]: message.lastActive,  // Update the lastActive timestamp for the corresponding user
        }));
      }
    };

    return () => {
      // useTrackLastActive()
      ws.close();  // Clean up WebSocket connection on component unmount
    };
  }, []);

  const handleRowClick = (user) => {
    navigate(`/admin-dashboard/students/${user._id}`, { state: { user } });
  };

  const handleCourseSelect = async (course) => {
    try {
      if(course === "All Students"){
        setUsers(dupe);
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/studentsRegistered?course=${course}`);
      // console.log(response.data);
      // console.log('called');
      // Assuming user has a unique identifier like 'userId', filter 'dupe' array to only include users in response.data
      const temp = dupe.filter((user1) => 
        response.data.some((user2) => user2.email === user1.googleAccount.split('.')[0])
      );
      // console.log(dupe);
      // console.log(temp);
      setUsers(temp);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  
  

  const handleSort = (param) => {
    const newOrder = sortOrder.field === param && sortOrder.order === 'asc' ? 'desc' : 'asc';
    setSortOrder({ field: param, order: newOrder });
    const sortedUsers = [...users].sort((a, b) => {
      const valueA = a[param];
      const valueB = b[param];
      if (param === 'dateOfBirth' || param==='lastActive' || param ==='dateRegistered') {
        const dateA =  valueA === "Not active yet" ? new Date('1970-10-23T09:20:38.403Z') : new Date(valueA) ;
        const dateB =  valueB === "Not active yet" ? new Date('1970-10-23T09:20:38.403Z') : new Date(valueB) ;

        if (newOrder === 'desc') {
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
        } else {
          if (dateA > dateB) return 1;
          if (dateA < dateB) return -1;
        }
        return 0;
      } else if (param === 'progress') {
        const progressA = Object.keys(valueA).length;
        const progressB = Object.keys(valueB).length;
        return newOrder === 'desc' 
          ? progressB - progressA  
          : progressA - progressB;
      }else if (typeof valueA === 'string' && typeof valueB === 'string') {
        return newOrder==='desc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else {
        return newOrder==='desc' ? valueA - valueB : valueB - valueA;
      }
    });
    setUsers(sortedUsers);
    // console.log(sortedUsers);
  };
  const getSortArrow = (param) => {
    if (sortOrder.field !== param) return ''; 
    return sortOrder.order === 'asc' ? '↑' : '↓';
  };

  return (
    <div className="p-8">
      <div>
        <h1 className="text-4xl font-bold mb-6 text-black-800">Student List</h1>
        <select onChange={(e) => handleCourseSelect(e.target.value)} >
          {courses && courses.map((course, index) => (
            <option key={index} value={course}>
              {course}
            </option>
          ))}
        </select>
      </div>
      <table className="min-w-full bg-white border border-gray-200 table-fixed">
      <thead>
        <tr className="bg-blue-300 text-left" style={{ height: '100px' }}>
          <th className="py-2 px-4 border-b-2 border-gray-300" onClick={() => handleSort('name')}>
            Name
            <p >
              {getSortArrow('name')}
            </p>
          </th>
          <th className="py-2 px-4 border-b-2 border-gray-300" onClick={() => handleSort('dateOfBirth')}>
            Date of Birth
            <p >
              {getSortArrow('dateOfBirth')}
            </p>
          </th>
          <th className="py-2 px-4 border-b-2 border-gray-300" onClick={() => handleSort('googleAccount')}>
            Email
            <p >
              {getSortArrow('googleAccount')}
            </p>
          </th>
          <th className="py-2 px-4 border-b-2 border-gray-300" onClick={() => handleSort('dateRegistered')}>
            Registration Time & Date
            <p>
              {getSortArrow('dateRegistered')}
            </p>
          </th>
          <th className="py-2 px-4 border-b-2 border-gray-300" onClick={() => handleSort('lastActive')}>
            Last Active
            <p >
              {getSortArrow('lastActive')}
            </p>
          </th>
          <th className="py-2 px-4 border-b-2 border-gray-300"  onClick={() => handleSort('progress')}>
            Progress
            <p>
              {getSortArrow('progress')}
            </p>
          </th>
        </tr>
      </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user, index) => (
              <tr 
                key={index} 
                className="hover:bg-gray-300 cursor-pointer" 
                onClick={() => handleRowClick(user)} // Add the row click handler
              >
                <td className="py-2 px-4 border-b border-gray-300  h-24">{user.name}</td>
                <td className="py-2 px-4 border-b border-gray-300  h-24">{new Date(user.dateOfBirth).toLocaleString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                </td>
                <td className="py-2 px-4 border-b border-gray-300  h-24">{user.googleAccount}</td>
                <td className="py-2 px-4 border-b border-gray-300  h-24">{new Date(user.dateRegistered).toLocaleString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                </td>
                <td className="py-2 px-4 border-b border-gray-300  h-24">
                  {user.lastActive && !isNaN(new Date(user.lastActive).getTime()) ? 
                    new Date(user.lastActive).toLocaleString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    }) : 'Not active yet'}
                </td>

                <td className="py-2 px-4 border-b border-gray-300  overflow-auto max-h-24">
                  {user.progress && Object.keys(user.progress).length > 0 ? (
                    <ul>
                      {Object.keys(user.progress).map((key) => (
                        <li key={key}><strong>{key}:</strong> {user.progress[key].join(', ')}</li>
                      ))}
                    </ul>
                  ) : (
                    <span className="text-red-600">No progress made</span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center py-4">No users found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default StudentList;

