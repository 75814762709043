import React from "react";
import "../styles/section_one.css"; // Import the CSS file
import image2 from "../images/image-2.jpeg";

function SectionOne() {
  return (
    <div className="container">
      <div className="row">
        <div className="text-column">
          <h1>
            We offer KS3 + GCSE Science lessons and A-level Physics sessions
          </h1>
          <hr className="horizontal_line"></hr>
          <p>
            Join our interactive and dynamic classroom to ensure your child's
            success!!!
          </p>

          <p>
            Lessons are led by a qualified teacher and they are exam orientated
            with the students receiving plenty of exam practice. Mock exams
            happen periodically and feedback is provided regularly.
          </p>
        </div>

        <div className="image-column">
          <img
            src={image2}
            alt="A descriptive alt text"
            className="section_one_image"
          />
        </div>
      </div>
    </div>
  );
}

export default SectionOne;