import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './dashboard.css'; // Import your Tailwind CSS


function Sidebar({ selected, setSelected }) {
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    if (location.pathname === '/admin-dashboard') {
      setSelected('dashboard');
    } else if (location.pathname === '/admin-dashboard/students') {
      setSelected('students');
    }
    else if (location.pathname === '/admin-dashoard/classes'){
      setSelected('classes');
    }
  }, [location, setSelected]);

  return (
    <div className="w-48 bg-green-300 min-h-screen p-6 flex flex-col">
      <Link 
        to="/admin-dashboard/dashboard" 
        className={`cursor-pointer py-3 px-4 mb-2 rounded-lg ${
          selected === 'dashboard' ? 'bg-green-500 text-black' : ''
        } hover:bg-gray-200`}
        onClick={() => setSelected('dashboard')}
      >
        Dashboard
      </Link>
      <Link 
        to="/admin-dashboard/students" 
        className={`cursor-pointer py-3 px-2 mb-2 rounded-lg ${
          selected === 'students' ? 'bg-green-500 text-black' : ''
        } hover:bg-gray-200`}
        onClick={() => setSelected('students')}
      >
        Students
      </Link>
      <Link 
        to="/admin-dashboard/classes" 
        className={`cursor-pointer py-3 px-2 mb-2 rounded-lg ${
          selected === 'classes' ? 'bg-green-500 text-black' : ''
        } hover:bg-gray-200`}
        onClick={() => setSelected('classes')}
      >
        Classes
      </Link>
    </div>
  );
}

export default Sidebar;
