import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login'; // Import MicrosoftLogin
import { useNavigate, Link } from 'react-router-dom';
import { registerWithGoogle, registerWithMicrosoft, register, logout } from '../redux/actions/authActions';
import '../styles/Register.css';
import { jwtDecode } from "jwt-decode";

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [userType, setUserType] = useState('student');
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      alert(error);
      // setError(null); 
    }
  }, [error]);

  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
  
        // Fetch user info from Google API
        const { data: googleData } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: 'application/json',
            },
          }
        );
  
        // Update state with user information
        setProfile(googleData);
        setEmail(googleData.email);
  
        const [firstName, lastName = ''] = googleData.name.split(' ');
        setFirstName(firstName);
        setLastName(lastName);
        setDateOfBirth(''); 
  
        await dispatch(registerWithGoogle(googleData, userType));
  
        alert('Registration successful! Please check your email to verify your account.');
        navigate('/verify-email');
      } catch (err) {
        if (err.response) {
          setError('Registration with Google failed: ' + err.response.data.message);
        } else if (err.request) {
          setError('No response from server.');
        } else {
          setError(err.message + ', Register with different account');
        }
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => setError('Login Failed: ' + error),
  });
  

  const authHandlerMicrosoft = async (err, data) => {
    if (err) {
      console.error('Login failed:', err);
      setError(`Microsoft Login Failed: ${err.message}`);
      return;
    }
  
    const { accessToken, idToken, account } = data;
  
    if (!accessToken || !idToken || !account) {
      console.error('Missing necessary data:', data);
      setError('Microsoft Login Failed: Missing data');
      return;
    }
  
    try {
      setLoading(true);
  
      const { data: microsoftData } = await axios.get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setProfile(microsoftData);
      setEmail(microsoftData.mail || microsoftData.userPrincipalName);
      setFirstName(microsoftData.givenName);
      setLastName(microsoftData.surname);
      setDateOfBirth(''); 

      await dispatch(registerWithMicrosoft(microsoftData, userType));
  
      alert('Registration successful! Please check your email to verify your account.');
      navigate('/verify-email');
    } catch (err) {
      if (err.response) {
        setError(`Registration with Microsoft failed: ${err.response.data.message}`);
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError(err.message + ', Register with different account');
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== retypePassword) {
      setError('Passwords do not match');
      return;
    }
    dispatch(register(firstName, lastName, email, password, dateOfBirth, userType)) // Use Redux action
      .then(() => {
        alert('Registration successful!');
        navigate('/'); 
      })
      .catch((error) => setError('Registration failed: ' + error.message))
      .finally(() => setLoading(false));
  };

  const logOut = () => {
    googleLogout();
    dispatch(logout());
    setProfile(null);
    setEmail('');
    setFirstName('');
    setLastName('');
    setDateOfBirth('');
  };

  return (
    <div className="register-container">
      {loading && (
        <div className="register-loading-overlay">
          <div className="register-spinner"></div>
        </div>
      )}

      {/* <h2 className='register-heading'>Register</h2>
      <p className="head-tag" >Use the below to validate</p> */}

      <div className="google-login">
        <button type="button" onClick={loginGoogle} className='buttonStyle'>
          <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" style={{height:"max",width:"auto"}} className='iconStyle' />
            Register with Google
        </button>
      </div>

      <div className="microsoft-login">
        <MicrosoftLogin
          clientId={process.env.REACT_APP_MS_CLIENT_ID}
          authCallback={authHandlerMicrosoft}
          redirectUri={window.location.origin}
          children={
            <button className="ms-buttonStyle">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                alt="Microsoft Logo"
                className="ms-iconStyle"
              />
              Register with Microsoft
            </button>
          }/>
      </div>
      {error? <p className='error-tag' >{error}. Please, Try again</p>: ""}
      <div className="login-link">
        <p>Already a member? <Link to="/login">Login here</Link></p>
      </div>
    </div>
  );
};

export default Register;
