import React from "react";
import "../styles/classes_card.css";

const ClassesCard = () => {
  return (
    <div className="classes_card_container">
      <h2 className="classes_card_header">Classes we offer:</h2>
      <div className="single_card_container">
        <div className="subject_card">
          <div className="card_header">KS3 Science</div>
          <div className="card_text">
            <div className="card_title">Years 6 to 8</div>
            <ul>
              <li>Establish a strong foundation in Biology, Chemistry, and Physics.</li>
              <li>Engage in interactive lessons and activities.</li>
              <li>Gauge progress with topic-specific assessments and constructive feedback.</li>
            </ul>
          </div>
        </div>

        <div className="subject_card">
          <div className="card_header">GCSE Science:</div>
          <div className="card_text">
            <div className="card_title">Years 9 to 11</div>
            <ul>
              <li>Master the GCSE science curriculum with comprehensive coverage.</li>
              <li>Practice exam techniques with real GCSE-style questions.</li>
              <li>Consolidate understanding with assessments and study materials.</li>
            </ul>
            
          </div>
        </div>

        <div className="subject_card">
          <div className="card_header">A-Level :</div>
          <div className="card_text">
            <div className="card_title">Years 12 to 13</div>
            <ul>
              <li>Delve into the exciting world of A-Level physics with comprehensive instruction.</li>
              <li>Strengthen understanding through assessments and quizzes.</li>
              <li>Receive personalized guidance to maximize potential.</li>
            </ul>
         
          </div>
        </div>
      </div>
      <div className="book_your_slots">
        {/* You can add booking functionality here */}
      </div>
    </div>
  );
};

export default ClassesCard;