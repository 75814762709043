import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../styles/testimonials.css";

const testimonialsData = [
  {
    name: "Aaliyah O",
    text: "I started enjoying Physics after I started tutoring sessions with Mr Siva, he makes complicated ideas and questions very easy to understand. I did really well in my GCSE Physics because of Mr Siva. Thank you!",
  },
  {
    name: "Kaji K",
    text: "My confidence grew in Sciences because of Mr Siva, I found Science's especially Chemistry really difficult before I started my lessons. After I started my lessons with Mr Siva I really started enjoying studying Chemistry. Thank you so much for your help over the last two years!",
  },
  {
    name: "Louis H",
    text: "I really enjoyed our Physics lessons and our in-depth conversations about Particle Physics. Thank you for making Physics enjoyable again!",
  },
];

const Testimonials = () => {
  return (
    <Container className="testimonials-container">
      <h2 className="testimonial_heading">Testimonials:</h2>
      <Row className="single_testimonial_container">
        {testimonialsData.map((testimonial, index) => (
          <Col key={index} md={4} className="testimonial-col">
            <Card className="testimonial_card">
              <Card.Body>
                <div className="quotation_container">
                  <span className="quotation_mark left">“</span>
                  <p className="testimonial_text">{testimonial.text}</p>
                  <span className="quotation_mark right">”</span>
                </div>
                <div className="testimonial_name_container">
                  <hr />
                  <Card.Footer className="text_muted">{testimonial.name}</Card.Footer>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Testimonials;