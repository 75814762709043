import { SET_COURSE, SET_HOMEWORK , RESET_CLASS} from "../actions/classActions"; 

const initialState = {
  course: '',
  homework: '',
};

const classReducer = (state = initialState, action) => {
  // console.log('Action received:', action);
  switch (action.type) {
    case SET_COURSE: 
      console.log('Setting course:', action.payload);
      return {
        ...state,
        course: action.payload,
        homework: '',
      };
    case SET_HOMEWORK: 
      return {
        ...state,
        homework: action.payload
      };
    case RESET_CLASS:
      return initialState; 
    default:
      return state; 
  }
};


export default classReducer;
