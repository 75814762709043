import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Classes.css'; 
import { useSelector } from 'react-redux';


const MarkerHome = () => {
  const navigate = useNavigate();
  const userType = useSelector((state)=>state.auth.userType);

  // Array of links and their paths
  const links = [
    { name: 'Show classes', path: '/marker/courses' },
    { name: 'Checklist', path: '/checklist' },
    { name: 'Upload A-level solutions', path: '/post-ans' },
    { name: 'Upload new quiz QnAs', path: '/post-homework' },
    { name: 'Upload quiz Notes', path: '/post-guide' },
  ];

  // Handle navigation on click
  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <>
      {(userType === 'admin' || userType === 'marker') ? (
        <div className="sst-classes-container">
          <h2 className="sst-classes-heading">Marker's Lobby</h2>
          {links.map((link, index) => (
            <div
              key={index}
              className="sst-class-link"
              onClick={() => handleLinkClick(link.path)}
            >
              {link.name.replace('_', ' ')}
            </div>
          ))}
        </div>
      ) : (
        <div
          className="unauthorized-message"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh', 
            fontSize: '1.5rem', 
            // color: '#ff4d4d', 
            padding: '20px', 
            textAlign: 'center', 
          }}
        >
          Page Unauthorized..
        </div>
      )}
    </>
  );
  
};

export default MarkerHome;
