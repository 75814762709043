import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/actions/authActions';
import '../styles/navbar.css'; // Ensure you modify or update this
import logoImage from '../images/Logo.png';
import { FaBars, FaUserCircle } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

const useAuth = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);

  let username = '';
  if (token) {
    try {
      username = jwtDecode(token).name;
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }

  return { isAuthenticated, userType, username };
};

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated, userType, username } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    navigate('/login');
  }, [dispatch, navigate]);

  const handleMobileprofile =()=>{
    navigate('/profile');
    setMenuOpen(!menuOpen);
  }

  return (
    <>
      <div className="navbar">
        <Link to="/" className="logo-container">
          <img src={logoImage} className="logoImg" alt="Logo" />
          <p className="logo-text">MrSivaScience Tutoring</p>
        </Link>

        {/* Hamburger Icon for Mobile, used to open and close sidebar */}
        <div className="hamburger-icon" onClick={toggleMenu}>
          <FaBars />
        </div>

        {/* Desktop Links (will be hidden on mobile) */}
        <div className="authButtons"> 
          {isAuthenticated ? (
            <>
          <div className="user-menu">
          <FaUserCircle className="user-icon" />
          <div className="user-menu-options">
                    <button onClick={() => navigate('/profile')}>Profile</button>
                    <button onClick={handleLogout}>Logout</button>
            </div>
            </div>
          </>
          ) : (
            <a href="/login">Login</a>
          )}
          <a href="/views/contactUs">Contact Us</a>
          <a href="/views/aboutUs">About Us</a>
          {isAuthenticated && userType === 'admin' && (
            <>
              <a href="/markerhome">Marker</a>
              <a href="/classes">SST-classes</a>
              <a href="/admin-dashboard">Dashboard</a>
            </>
          )}
           {userType === 'marker' && (
            <>
              <a href="/markerhome">Marker</a>
            </>
          )}
        </div>
      </div>

      {/* Sidebar Menu for Mobile */}
      <div className={menuOpen ? 'nav-sidebar open' : 'nav-sidebar'}>
        <div className="sidebar-header" onClick={()=>handleMobileprofile()}>
          <FaUserCircle className="user-icon-sidebar" /> {username || ''}
        </div>

        <ul className="sidebar-links">
          <li onClick={() => setMenuOpen(!menuOpen)}><Link to="/views/contactUs">Contact Us</Link></li>
          <li onClick={() => setMenuOpen(!menuOpen)}><Link to="/views/aboutUs">About Us</Link></li>
          {isAuthenticated && userType === 'admin' && (
            <div onClick={() => setMenuOpen(!menuOpen)}>
              <li><Link to="/markerhome">Marker</Link></li>
              <li><Link to="/classes">SST-classes</Link></li>
              <li><Link to="/admin-dashboard">Dashboard</Link></li>
            </div>
          
          )}
          {isAuthenticated && userType === 'marker' && (
            <li onClick={() => setMenuOpen(!menuOpen)}><Link to="/markerhome">Marker</Link></li>
          )}
          {isAuthenticated ? (
            <li onClick={() => setMenuOpen(!menuOpen)}>
              <button onClick={handleLogout}>Logout</button>
            </li>
          ) : (
            <li onClick={() => setMenuOpen(!menuOpen)}><Link to="/login">Login</Link></li>
          )}
        </ul>

        {/* Remove the Close Button; hamburger icon will close the sidebar */}
      </div>

      {/* Optional Overlay when the menu is open */}
      {menuOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </>
  );
};


export default Navbar;
