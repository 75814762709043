import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Courses.css'; 
import { useSelector } from 'react-redux';
import { FaPencilAlt } from 'react-icons/fa';  
import { ThreeDots } from 'react-loader-spinner';
import { PiStudentBold } from "react-icons/pi";

const MarkerCourses = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const userType = useSelector((state) => state.auth.userType);
  const [loading, setLoading] = useState(false);

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/courses`);
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const handleCourseClick = (course) => {
    localStorage.setItem('course', course);
    navigate(`/marker/homeworks`);
  };

  const handleChange = async (oldCourseName) => {
    const newCourseName = prompt(`What do you want to change the course name "${oldCourseName.replace(/_/g,' ')}" to?`, oldCourseName);
    if (newCourseName && newCourseName !== oldCourseName) {
      setLoading(true);
      try {
        await axios.put(`${process.env.REACT_APP_BE_URL}/admin/update-course-name`, {
          oldCourseName: oldCourseName,
          newCourseName: newCourseName.trim().replace(/\s/g,'_')
        });
        setCourses((prevCourses) => 
          prevCourses.map(course => course === oldCourseName ? newCourseName.trim().replace(/\s/g,'_') : course)
        );
        alert(`${oldCourseName} has been changed to ${newCourseName}`);
      } catch (error) {
        alert(error);
        console.error('Error updating course name:', error);
      } finally{
        setLoading(false);
      }
    }
  };

  return (
    <div className="marker-course-container">
      <h2 className="marker-courses-heading">Classes</h2>
      {loading ? (
        <div className="loader-container">
          <ThreeDots color="#004aad" height={50} width={50} />
        </div>
      ):(<div className="marker-courses-cards">
        {courses.map((course, index) => (
          <li key={index}>
            <div 
              className={userType === 'admin' || userType === 'marker' ? "marker-course-card-admin" : "marker-course-card"} 
              onClick={() => handleCourseClick(course)}
            >
              <div className="course-name">
                <h3>{course.replace(/_/g, ' ')}</h3>
              </div>
              {userType === 'admin' && (
                <div className="edit-icon-container">
                  <PiStudentBold 
                    className="edit-icon" 
                    onClick={(e) => {
                      e.stopPropagation();  
                      localStorage.setItem('course',course);
                      navigate('/all-students-report')
                    }} 
                  />
                  <FaPencilAlt 
                    className="edit-icon" 
                    onClick={(e) => {
                      e.stopPropagation();  
                      handleChange(course);
                    }} 
                  />
                </div>
              )}
              {userType === 'marker' && (
                <div className="edit-icon-container">
                  <PiStudentBold 
                    className="edit-icon" 
                    onClick={(e) => {
                      e.stopPropagation();  
                      localStorage.setItem('course',course);
                      navigate('/all-students-report');
                    }} 
                  />
                </div>
              )}
            </div>
          </li>
        ))}
      </div>)}
    </div>
  );
};

export default MarkerCourses;
